.date-picker-noborder {
  width: 120px;
  input {
    padding: 0;
    border: 0;
    color: #aeaeaeff;
    &:focus {
      box-shadow: none;
    }
  }
}
.date-picker-noborder-container {
  .ant-calendar-footer {
    border-top: none;
  }
  .ant-calendar-footer-extra {
    text-align: center;
    .ant-btn-primary {
      margin-left: 8px;
    }
  }
}

.pro-detail-smailtitle {
  .ant-descriptions-item-content {
    vertical-align: top;
  }
}

.compass-title {
  margin-left: 12px;
  .info {
    margin-bottom: 15px;
  }
}
.compass-upload {
  // display: flex;
  margin-top: 40px;
  .compass-label {
    width: 140px;
    text-align: right;
  }
  .dragger-box {
    margin-left: 140px;
  }
}